@font-face {
    font-family: 'Tiresias';
    src: url('../public/tiresias.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;    
  }

  @import url('https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@700&display=swap');
  
  body {
    font-family: 'Tiresias', sans-serif !important;
    margin: 0;
    
  }
