.divcontato {
    background-color: white;
    padding: 2rem;
    width: 30%;
    box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
    border-color: black;
    border-radius: 0.3rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    
}

.divcontato:hover {
    box-shadow: 0px 0px 7px 8px rgba(0, 0, 0, 0.2);
    
}

.bordabranca:after {
    border-bottom: 2px solid white !important;
}

.bordabranca:before {
    border-bottom: 1px solid rgba(239, 184, 16, 0.40) !important;
}

.css-1hxh6ak-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before{
    border-bottom: 2px solid rgba(239, 184, 16, 0.80) !important;
}

.fcainputs fieldset{
    border-color: white !important;
    
}

.fcainputs label, .fcainputs p, .fcainputs svg, .fcainputs div{
    color: white !important;
}
.fcainputs {
    width: 45% ;
}

