.menuitemfont {
  font-size: 0.8rem !important;
}

.menumobile .menuitemfont {
  font-size: 0.9rem !important;
  margin-bottom: 1rem;
}

.diventrar {
  background-color: white;
  padding: 2rem;
  width: 40rem;
  box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
  border-color: black;
  border-radius: 0.3rem;
  display: flex;
  overflow: hidden;

}

.diventrarmobile {
  background-color: white;
  padding: 2rem;
  width: 100%;
  box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
  border-color: black;
  border-radius: 0.3rem;
  display: flex;
  overflow: hidden;
  flex-direction: column;

}

.diventrargeral-hidden {
  transform: translateX(110%);
  transition: transform 0.7s ease-in-out;
}

.diventrargeral-shown {
  transform: translateX(0%);
  transition: transform 0.7s ease-in-out;
}

.diventrargeral {
  position: fixed;
  top: 3px;
  right: 3px;
  z-index: 900;
}
.diventrargeralmobile {
  position: fixed;
  top: 7rem;
  right: 0;
  left: 0;
  
  margin: auto;
  z-index: 900;
}

.divcadastrar {
  background-color: white;
  padding: 2rem;
  width: 25rem;
  box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
  border-color: black;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.divcadastrarmobile {
  background-color: white;
  padding: 2rem;
  width: 100%;
  box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
  border-color: black;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.divcadastrargeralmobile {
  position: fixed;
  top: 7rem;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 999;
}
.divcadastrargeral {
  position: fixed;
  top: 3px;
  right: 3px;
  z-index: 999;
}

.divcadastrargeral-hidden {
  transform: translateX(110%);
  transition: transform 0.5s ease-in-out;
}

.divcadastrargeral-shown {
  transform: translateX(0%);
  transition: transform 0.5s ease-in-out;
}

.btnclose:hover, .btndelete:hover {
  opacity: 0.8;
}

.btnclose, .btndelete {
  width: 1.5rem !important;
}

.divesqueceu {
  background-color: white;
  padding: 2rem;
  width: 25rem;
  box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
  border-color: black;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.divesqueceugeral {
  position: fixed;
  top: 3px;
  right: 3px;
  z-index: 999;
}

.divesqueceugeral-hidden {
  transform: translateX(110%);
  transition: transform 0.5s ease-in-out;
}

.divesqueceugeral-shown {
  transform: translateX(0%);
  transition: transform 0.5s ease-in-out;
}


.hidden-menu-slide {
  transition: max-height 0.8s ease-in-out;
  overflow: hidden;
}

.hidden-menu-hidden {
  max-height: 0;
  border-top: 0px;
}

.hidden-menu-visible {

  max-height: 200px;
  /* Ajuste este valor de acordo com a altura desejada para o menu escondido */

}

.menuitemfontcor {
  color: white !important;
}

.menulink {
  text-decoration: none !important;
  color: rgb(85, 139, 47);
  margin-right: 20px;
  cursor: pointer;
}

.active {
  text-decoration: none !important;
  color: inherit !important;
}


.divcarrinho {
  background-color: white;

  width: 25rem;
  box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
  border-color: black;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.divcarrinhomobile {
  background-color: white;

  width: 100%;
  box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
  border-color: black;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.divcarrinhogeral {
  position: fixed;
  top: 4.2rem;
  right: 3px;
  z-index: 999;
}
.divcarrinhogeralmobile {
  position: fixed;
  top: 7rem;
  right: 0;
  left: 0;
  z-index: 999;
}

.divcarrinhogeral-hidden {
  transform: translateX(110%);
  transition: transform 0.5s ease-in-out;
}

.divcarrinhogeral-shown {
  transform: translateX(0%);
  transition: transform 0.5s ease-in-out;
}




.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  /* Ou qualquer outra cor de sua escolha */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  /* Ou qualquer outro valor que garanta que o loader fique em cima de tudo */
  border-radius: 0.3rem;
}

.loaderConta {
  position: absolute;
  top: 0;
  right: 0;

  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  /* Ou qualquer outra cor de sua escolha */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  /* Ou qualquer outro valor que garanta que o loader fique em cima de tudo */
  border-radius: 0.3rem;
}

.loaderRecomendacoes {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  /* Ou qualquer outra cor de sua escolha */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  /* Ou qualquer outro valor que garanta que o loader fique em cima de tudo */
  border-radius: 0.3rem;
  align-items: flex-start;
}


.btnplusless {
  width: 1.2rem !important;

}

.btnplusless:hover {
  width: 2rem !important;
}

#root {
  overflow: hidden;
}

.hover-icon:hover{
  width: 1.4rem !important;
  
}