.slick-track div {
  height: auto !important;
}
  .slick-dots li button:before {
    color: white !important;
  }
  .slick-dots li.slick-active button:before {
    color: white !important;
  }

  