.hovermenuprincipal:hover {
    background-color: rgb(247, 240, 234);
    cursor: pointer;
}

.divAlterarRespmobile {
    position: fixed;
    top: 7rem;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 999;
  }
  .divAlterarRespgeral {
    position: fixed;
    top: 1.5rem;
    left: 1.5rem;
    z-index: 999;
  }
  
  .divAlterarRespgeral-hidden {
    transform: translateX(-110%);
    transition: transform 0.5s ease-in-out;
  }
  
  .divAlterarRespgeral-shown {
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out;
  }

  .divalterarresp {
    background-color: white;
    padding: 2rem;
    width: 25rem;
    box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
    border-color: black;
    border-radius: 0.3rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .divAlterarSenhageral {
    position: fixed;
    top: 1.5rem;
    left: 1.5rem;
    z-index: 999;
  }
  
  .divAlterarSenhageral-hidden {
    transform: translateX(-110%);
    transition: transform 0.5s ease-in-out;
  }
  
  .divAlterarSenhageral-shown {
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out;
  }

  .divalterarSenha {
    background-color: white;
    padding: 2rem;
    width: 25rem;
    box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
    border-color: black;
    border-radius: 0.3rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }