.linksolicitacao:hover {
text-decoration: underline;
}

.loaderSolicitacoes {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7); /* Ou qualquer outra cor de sua escolha */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100; /* Ou qualquer outro valor que garanta que o loader fique em cima de tudo */
    border-radius: 0.3rem;
  }