.icones {
    width: 3rem !important;
    height: auto !important;
    
}

.divportfolio {
    cursor: pointer;
}

.divportfolio img {
    transform: scale(1);
    transition: transform ease 0.3s;
}

.divportfolio .divtotal:hover img{
    transform: scale(1.1);
}

.divportfolio .divimg {
    overflow: hidden;
}

.divvantagens div div div p{
    font-size: 0.8rem !important;
}

.divchamadatv {
    background-image: url('assets/tvagross/fundotvagross.jpg') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}
