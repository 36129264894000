.divfooter {
    background-image: url('assets/footer/bgfooter.png');
    background-position: bottom right;
    height: 30rem;
    background-repeat: no-repeat;
    
}

.line {
    border-bottom: 4px solid #eddd5e;
    border-radius: 5px;    
    width: 2rem;
}


.divfooter a {
    text-decoration: none;
    color: #eddd5e !important;
}

.divfooter a:hover {
    color: white !important;
}