.divaminoagross {
    position: relative;
    width: 100%;
    height: 40rem;
    background-image: url('assets/portfolio/aminoagross.jpg');
    background-size: cover;
    background-position: center;
    border-radius: 0.5rem;
    cursor: pointer;
}




.divsemente1051 {
    position: relative;
    width: 100%;
    height: 40rem;
    background-image: url('assets/portfolio/sementedemilho.jpg');
    background-size: cover;
    background-position: center;
    border-radius: 0.5rem;
    cursor: pointer;
}

.divaminoagross a {
text-decoration: none;
}

.descricaoprodutos {
    display: none !important;
}

.divaminoagross:hover .descricaoprodutos, .divsemente1051:hover .descricaoprodutos {
    display: inline-flex !important;
}

.divaminoagross::before, .divsemente1051::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 0, transparent 70%);
    border-radius: 5px;
}

.divaminoagross:hover:before, .divsemente1051:hover:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 1.2) 0, transparent 90%);
    border-radius: 5px;
}


.backcard {
    background-color: lightgray;
}