.flipclockdiv div{
  background-color: #ff6900;
  width: 10rem;
  height: 10rem;
  border-radius: 0.5rem;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
  box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.3);
}



.flipclockdiv .spannumero{
  margin-left: auto;
  margin-right: auto;
  font-size: 7rem;
  color: white;
  
}

.flipclockdiv p {
  font-size: 6rem;
  color: #ff6900;
}

.descricaoclock {
  color: #ff6900;
  font-size: 1.3rem;
}
.flipclockdivmobile div{
  background-color: #ff6900;
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
  box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.3);
}

.flipclockdivmobile .spannumero{
  margin-left: auto;
  margin-right: auto;
  font-size: 2rem;
  color: white;
  
}

.flipclockdivmobile p {
  font-size: 2rem;
  color: #ff6900;
}

.descricaoclockmobile {
  color: #ff6900;
  font-size: 0.8rem;
}