@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";


.divpassos {
    background-color: white;
    padding: 2rem;
    width: 80%;
    box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
    border-color: black;
    border-radius: 0.3rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    
    
}

.divpassos:hover {
    box-shadow: 0px 0px 7px 8px rgba(0, 0, 0, 0.2);
    
}